import {
  type AccountGeneralReview,
  useAccountProfileStore,
} from '~/stores/accountProfile';

export default defineNuxtRouteMiddleware(async () => {
  const {isAuth} = useAuthorizationStore();

  if (!isAuth) return;

  const {fetchAccountProfileGeneralReviews, setAccountGeneralReviews} =
    useAccountProfileStore();

  const {status, data} = await fetchAccountProfileGeneralReviews();

  if (status.value === 'success') {
    setAccountGeneralReviews(
      (data.value?.items as unknown as AccountGeneralReview[]) || [],
    );
  }
});
